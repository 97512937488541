import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlift 4×6\\@80%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`10-Deadlifts (225/155)`}</p>
    <p>{`15-Bar Facing Burpees`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our FREE New Years Day workout will be at 10:30.  Bring a friend!
 All other class times and open gym times are cancelled.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The 6:00pm class on New Years Eve is cancelled.  There will be no
open gym after 6:00pm.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      